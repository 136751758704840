import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from '@angular/core';
import { PresetItem } from '../model/model';

@Component({
  selector: 'mat-drp-presets',
  template: `<div>
  <ul class="ngx-mat-drp-presets-list">
    <li *ngFor="let preset of presets" (click)="setPresetPeriod(preset)"> {{preset.presetLabel}} </li>
  </ul>
</div>`,
  styles: [`.ngx-mat-drp-presets-list{list-style-type:none;margin:0;padding:0}.ngx-mat-drp-presets-list li{margin:5px 0;padding:4%;cursor:pointer;background:#fafafa;color:#3f51b5;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.ngx-mat-drp-presets-list li:hover{background:#3f51b5;color:#fff}`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresetsComponent implements OnInit {
  @Input()
  presets: Array<PresetItem>;
  @Output()
  readonly presetChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  setPresetPeriod(event) {
    this.presetChanged.emit(event);
  }
}
