import {
  Component,
  ViewChild,
  Output,
  Input,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { ConfigStoreService } from '../services/config-store.service';

@Component({
  selector: 'calendar-wrapper',
  template: `<div>

  <!-- <mat-divider></mat-divider> -->
  <span class="ngx-mat-drp-date-label">
    <label>{{prefixLabel}}</label>
    <label class="ngx-mat-drp-selected-date-label">{{selectedDate | date:dateFormat}}</label>
  </span>
  <!-- <mat-divider></mat-divider> -->

  <mat-calendar 
    [startAt]="selectedDate"
    [selected]="selectedDate"
    [minDate]="minDate"
    [maxDate]="maxDate"
    (selectedChange)="onSelectedChange($event)"
    (yearSelected)="onYearSelected($event)"
    (_userSelection)="onUserSelection($event)"
    [dateFilter]="weekendFilter">
  </mat-calendar>

</div>`,
  styles: [`.ngx-mat-drp-date-label{background:#fafafa;margin:15px;padding:4px 2px;width:100%;font-size:14px;font-weight:500}.ngx-mat-drp-selected-date-label{color:rgba(0,0,0,.38);padding-left:5%}`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarWrapperComponent implements OnChanges {
  @ViewChild(MatCalendar)
  matCalendar: MatCalendar<Date>;

  @Output()
  readonly selectedDateChange: EventEmitter<Date> = new EventEmitter<Date>();

  dateFormat: string;
  @Input() selectedDate: Date;
  @Input() prefixLabel: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  weekendFilter = (d: Date) => true;

  constructor(private configStore: ConfigStoreService) {
    this.dateFormat = configStore.ngxDrpOptions.format;
    if (configStore.ngxDrpOptions.excludeWeekends) {
      this.weekendFilter = (d: Date): boolean => {
        const day = d.getDay();
        return day !== 0 && day !== 6;
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Necessary to force view refresh
    this.matCalendar.activeDate = changes.selectedDate.currentValue;
  }

  onSelectedChange(date) {
    this.selectedDateChange.emit(date);
  }

  onYearSelected(e) {}

  onUserSelection(e) {}
}
