import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';

import { isPresent } from '../util/lang';

export const dateISO: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } => {
    if (isPresent(Validators.required(control))) {
        return null;
    }

    let v: string = control.value;
    return /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(v) ? null : { dateISO: true };
};
