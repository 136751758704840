import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { email } from './validator';

const EMAIL_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EmailValidator),
    multi: true
};

@Directive({
    selector: '[email][formControlName],[email][formControl],[email][ngModel]',
    providers: [EMAIL_VALIDATOR]
})
export class EmailValidator implements Validator {
    validate(c: AbstractControl): { [key: string]: any } {
        return email(c);
    }
}
