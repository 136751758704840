import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PresetItem, NgxDrpOptions } from '../model/model';
import { RangeStoreService } from '../services/range-store.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { ConfigStoreService } from '../services/config-store.service';
import { pickerOverlayAnimations } from './picker-overlay.animations';

@Component({
  selector: 'ngx-mat-drp-picker-overlay',
  template: `<div [@transformPickerOverlay]="shouldAnimate" class="ngx-mat-drp-calendar-container">

  <div class="ngx-mat-drp-calendar-item">
    <calendar-wrapper 
    [prefixLabel]="startDatePrefix"
    [selectedDate]="fromDate"
    [minDate]="fromMinDate"
    [maxDate]="fromMaxDate"
    (selectedDateChange)="updateFromDate($event)">
  </calendar-wrapper>
  </div>
  <div class="ngx-mat-drp-calendar-item">
    <calendar-wrapper 
    [prefixLabel]="endDatePrefix"
    [selectedDate]="toDate"
    [minDate]="toMinDate"
    [maxDate]="toMaxDate" 
    (selectedDateChange)="updateToDate($event)">
  </calendar-wrapper>
  </div>
  <div class="ngx-mat-drp-calendar-item">
    <div class="ngx-mat-drp-menu">
      <mat-drp-presets [presets]="presets" (presetChanged)="updateRangeByPreset($event)"></mat-drp-presets>
      <div class="ngx-mat-drp-controls">
        <button mat-button color="primary" (click)="applyNewDates($event)">{{applyLabel}}</button>
        <button mat-button (click)="discardNewDates($event)">{{cancelLabel}}</button>
      </div>
    </div>
  </div>
</div>
`,
  styles: [`.ngx-mat-drp-calendar-container{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-pack:distribute;justify-content:space-around;min-width:650px;height:300px}.ngx-mat-drp-calendar-item{-ms-flex-preferred-size:33.33%;flex-basis:33.33%;min-width:210px;padding:1em;font-family:Roboto,"Helvetica Neue",sans-serif;font-size:14px;font-weight:400}.ngx-mat-drp-menu{height:100%}.ngx-mat-drp-controls{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-pack:distribute;justify-content:space-around;margin:10% auto}.ngx-mat-drp-overlay{-webkit-box-shadow:0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12);box-shadow:0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12);background:#fff;border-radius:2px}.ngx-mat-drp-overlay-backdrop{background-color:rgba(0,0,0,.2);opacity:.2}`],
  animations: [pickerOverlayAnimations.transformPanel],
  encapsulation: ViewEncapsulation.None
})
export class PickerOverlayComponent implements OnInit {
  fromDate: Date;
  toDate: Date;
  fromMinDate: Date;
  fromMaxDate: Date;
  toMinDate: Date;
  toMaxDate: Date;
  presets: Array<PresetItem> = [];
  startDatePrefix: string;
  endDatePrefix: string;
  applyLabel: string;
  cancelLabel: string;
  shouldAnimate: string;

  constructor(
    private rangeStoreService: RangeStoreService,
    private configStoreService: ConfigStoreService,
    private overlayRef: OverlayRef
  ) {}

  ngOnInit() {
    this.fromDate = this.rangeStoreService.fromDate;
    this.toDate = this.rangeStoreService.toDate;
    this.startDatePrefix = this.configStoreService.ngxDrpOptions.startDatePrefix || 'FROM:';
    this.endDatePrefix = this.configStoreService.ngxDrpOptions.endDatePrefix || 'TO:';
    this.applyLabel = this.configStoreService.ngxDrpOptions.applyLabel || 'Apply';
    this.cancelLabel = this.configStoreService.ngxDrpOptions.cancelLabel || 'Cancel';
    this.presets = this.configStoreService.ngxDrpOptions.presets;
    this.shouldAnimate = this.configStoreService.ngxDrpOptions.animation
      ? 'enter'
      : 'noop';
    ({
      fromDate: this.fromMinDate,
      toDate: this.fromMaxDate
    } = this.configStoreService.ngxDrpOptions.fromMinMax);
    ({
      fromDate: this.toMinDate,
      toDate: this.toMaxDate
    } = this.configStoreService.ngxDrpOptions.toMinMax);
  }

  updateFromDate(date) {
    this.fromDate = date;
  }

  updateToDate(date) {
    this.toDate = date;
  }

  updateRangeByPreset(presetItem: PresetItem) {
    this.updateFromDate(presetItem.range.fromDate);
    this.updateToDate(presetItem.range.toDate);
  }

  applyNewDates(e) {
    this.rangeStoreService.updateRange(this.fromDate, this.toDate);
    this.disposeOverLay();
  }

  discardNewDates(e) {
    // this.rangeStoreService.updateRange();
    this.disposeOverLay();
  }

  private disposeOverLay() {
    this.overlayRef.dispose();
  }
}
